let ServiceMainPageLayout = () => import(/* webpackChunkName: "User" */'../components/Service/layout/MainPageLayout')
let ServiceHomePage = () => import(/* webpackChunkName: "User" */'../components/Service/HomePage')
let ServiceContributionListPage = () => import(/* webpackChunkName: "User" */'../components/Service/ContributionListPage')
let ServiceReportListPage = () => import(/* webpackChunkName: "User" */'../components/Service/ReportListPage')
let ServiceReportDetailPage = () => import(/* webpackChunkName: "User" */'../components/Service/ReportDetailPage')
let ServiceTagListPage = () => import(/* webpackChunkName: "User" */'../components/Service/TagListPage')
let ServiceProfilePage = () => import(/* webpackChunkName: "User" */'../components/Service/ProfilePage')
let ServiceMessagePage = () => import(/* webpackChunkName: "User" */'../components/Service/MessagePage')
let ServicePasswordPage = () => import(/* webpackChunkName: "User" */"../components/Service/PasswordPage")
let ServiceFavoriteListPage = () => import(/* webpackChunkName: "User" */"../components/Service/FavoriteListPage")
let ServiceFavoriteDetailPage = () => import(/* webpackChunkName: "User" */"../components/Service/FavoriteDetailPage")
let ServiceRatingPage = () => import(/* webpackChunkName: "User" */"../components/Service/RatingPage")
let ServiceCartPage = () => import(/* webpackChunkName: "User" */"../components/Service/CartPage")

let routes = {
    path: 'service',
    component: ServiceMainPageLayout,
    meta: {
        user_only: true
    },
    children: [{
        path: '',
        name: 'service_home_page',
        component: ServiceHomePage,
        meta: {
            user_only: true
        }
    }, {
        path: 'messages',
        name: 'service_message_page',
        component: ServiceMessagePage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            checked: route.query.checked === 'true' || route.query.checked === true,
        }),
        meta: {
            user_only: true
        },
    }, {
        path: 'favorites',
        name: 'service_favorite_list_page',
        component: ServiceFavoriteListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1
        }),
        meta: {
            user_only: true
        },
    }, {
        path: 'favorites/:favoriteId',
        name: 'service_favorite_detail_page',
        component: ServiceFavoriteDetailPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            model: route.query.model
        }),
        meta: {
            user_only: true
        },
    }, {
        path: 'contributions',
        name: 'contribution_list_page',
        component: ServiceContributionListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            model: route.query.model ? route.query.model : "",
        }),
        meta: {
            user_only: true
        }
    }, {
        path: 'reports',
        name: 'report_list_page',
        component: ServiceReportListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            model: route.query.model ? route.query.model : "",
            status: route.query.status ? route.query.status : ""
        }),
        meta: {
            user_only: true
        }
    }, {
        path: 'reports/:reportId',
        name: 'report_detail_page',
        component: ServiceReportDetailPage,
        meta: {
            user_only: true
        }
    }, {
        path: 'tags',
        name: 'tag_list_page',
        component: ServiceTagListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1
        }),
        meta: {
            user_only: true,
            expert_only: true
        }
    }, {
        path: 'ratings',
        name: 'rating_page',
        component: ServiceRatingPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            min: route.query.min ? parseFloat(route.query.min): 0,
            max: route.query.max ? parseFloat(route.query.max): 5,
            attitude: route.query.attitude,
            model: route.query.model,
            sorter: route.query.sorter,
        }),
        meta: {
            user_only: true
        }
    }, {
        path: 'cart',
        name: 'cart_page',
        component: ServiceCartPage,
        meta: {
            user_only: true
        }
    }, {
        path: 'profile',
        name: 'profile_page',
        component: ServiceProfilePage,
        meta: {
            user_only: true
        }
    }, {
        path: 'password',
        name: 'password_page',
        component: ServicePasswordPage,
        meta: {
            user_only: true
        }
    }]
}

export default routes
