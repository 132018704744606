import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import routes from './main'
import { validate } from "../utils";
import {checkSession} from "../assets/javascripts/api/session";

Vue.use(VueRouter)

let router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    store.commit("toggleMenu", false);
    if (to.path && window._hmt) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
    checkSession().then(result => {
        store.commit("updateProfile", result.data);
    }).catch(error => {
        store.commit("updateProfile", '')
    }).finally(() => {
        if (to.meta.super_only) {
            if (validate(store.state.profile, { superOnly: true })) {
                next();
            }
            else {
                next({
                    name: 'home_page'
                });
            }
        }
        else if (to.meta.admin_only) {
            if (validate(store.state.profile, { adminOnly: true })) {
                next();
            }
            else {
                next({
                    name: 'home_page'
                });
            }
        }
        else if (to.meta.user_only) {
            if (to.meta.expert_only) {
                if (validate(store.state.profile, { expertOnly: true })) {
                    next()
                }
                else {
                    next({
                        name: 'home_page'
                    });
                }
            }
            else if (validate(store.state.profile, { userOnly: true })) {
                next();
            }
            else {
                next({
                    name: 'home_page'
                });
            }
        }
        else if (to.meta.sign_in_required) {
            if (validate(store.state.profile, { noGuest: true })) {
                next();
            }
            else {
                next({
                    name: 'home_page'
                });
            }
        }
        else {
            next();
        }
    });
});

router.afterEach((to, from) => {
    store.commit("updateNavigation");
    store.commit("updateCurrentIndex", to.path);
    store.commit("updateLastPage", from.path);
});

export default router