
function generate_noncestr (len) {
    function rand_int(min, max) {
        return Math.floor(parseFloat(min) + Math.random() * parseFloat(max - min));
    }
    let alphabet = '1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
    let result = [];
    for(let i = 0; i < len; i++) {
        result.push(alphabet.charAt(rand_int(0, alphabet.length - 1)));
    }
    return result.join('');
}

export default {
    generate_noncestr: generate_noncestr
}