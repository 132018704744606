// 后台管理
import SearchEnginePage from "@/components/Console/SearchEnginePage.vue";

let ConsoleMainPageLayout = () => import(/* webpackChunkName: "Admin" */"../components/Console/layout/MainPageLayout")
let ConsoleHomePage = () => import(/* webpackChunkName: "Admin" */"../components/Console/HomePage.vue")
let ConsoleRoleListPage = () => import (/* webpackChunkName: "Admin" */"../components/Console/RoleListPage.vue")
let ConsolePrivilegeListPage = () => import (/* webpackChunkName: "Admin" */"../components/Console/PrivilegeListPage.vue")
let ConsoleReportListPage = () => import(/* webpackChunkName: "Admin" */"../components/Console/ReportListPage.vue")
let ConsoleReportDetailPage = () => import(/* webpackChunkName: "Admin" */"../components/Console/ReportDetailPage.vue")
let ConsoleSettingPage = () => import(/* webpackChunkName: "Admin" */"../components/Console/SettingPage.vue")
let ConsoleTagListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/TagListPage')
let ConsoleUserListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/UserListPage')
let ConsoleUserDetailPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/UserDetailPage')
let ConsolePersonListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/PersonListPage')
let ConsolePersonAnalysisListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/PersonAnalysisListPage')
let ConsolePersonAnalysisDetailPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/PersonAnalysisDetailPage')
let ConsoleWorkListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/WorkListPage')
let ConsoleRecentWorkListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/RecentWorkListPage')
let ConsoleSeriesListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/SeriesListPage')
let ConsoleBookListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/BookListPage')
let ConsoleBookSeriesListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/BookSeriesListPage')
let ConsolePlatformListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/OnlinePlatformListPage')
let ConsoleOrgListPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/OrganizationListPage')
let ConsoleMessagePage = () => import(/* webpackChunkName: "Admin" */'../components/Console/MessagePage')
let ConsolePublicationStat = () => import(/* webpackChunkName: "Admin" */'../components/Console/PublicationStat')
let ConsoleWorkStat = () => import(/* webpackChunkName: "Admin" */'../components/Console/WorkStat')
let ConsolePeopleStat = () => import(/* webpackChunkName: "Admin" */'../components/Console/PeopleStat')
let ConsoleOrganizationStat = () => import(/* webpackChunkName: "Admin" */'../components/Console/OrgStat')
let ConsoleBrowseRecordStat = () => import(/* webpackChunkName: "Admin" */'../components/Console/BrowseRecordStat')
let ConsoleActionLogList = () => import(/* webpackChunkName: "Admin" */'../components/Console/ActionLogList')
let ConsoleFullTextList = () => import(/* webpackChunkName: "Admin" */'../components/Console/FulltextListPage')
let ConsoleFullTextDetail = () => import(/* webpackChunkName: "Admin" */'../components/Console/FullTextDetailPage')
let ConsoleFullTextSearchPage = () => import(/* webpackChunkName: "Admin" */'../components/Console/FullTextSearchPage.vue')
let ConsoleSearchEnginePage = () => import(/* webpackChunkName: "Admin" */'../components/Console/SearchEnginePage.vue')

let router = {
    path: '/console',
    component: ConsoleMainPageLayout,
    meta: {
        admin_only: true
    },
    children: [{
        path: '/',
        name: 'console_home_page',
        component: ConsoleHomePage,
        meta: {
            admin_only: true
        },
    }, {
        path: 'messages',
        name: 'console_message_page',
        component: ConsoleMessagePage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
            checked: route.query.checked === 'true' || route.query.checked === true,
        }),
        meta: {
            admin_only: true
        },
    }, {
        path: 'action_logs',
        name: 'action_log_list_page',
        component: ConsoleActionLogList,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
        }),
        meta: {
            admin_only: true
        },
    }, {
        path: 'search',
        name: 'search_engine_page',
        component: SearchEnginePage,
        meta: {
            admin_only: true
        },
    }, {
        path: 'stat/book',
        name: 'console_book_stat',
        component: ConsolePublicationStat,
        meta: {
            admin_only: true
        },
    }, {
        path: 'stat/br',
        name: 'console_browse_record_stat',
        component: ConsoleBrowseRecordStat,
        meta: {
            admin_only: true
        },
    }, {
        path: 'stat/work',
        name: 'console_work_stat',
        component: ConsoleWorkStat,
        meta: {
            admin_only: true
        },
    }, {
        path: 'stat/person',
        name: 'console_people_stat',
        component: ConsolePeopleStat,
        meta: {
            admin_only: true
        },
    }, {
        path: 'stat/org',
        name: 'console_org_stat',
        component: ConsoleOrganizationStat,
        meta: {
            admin_only: true
        },
    }, {
        path: 'stat/people',
        name: 'console_person_analysis_list_page',
        component: ConsolePersonAnalysisListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : "",
            query: route.query.query ? route.query.query : "",
            gender: route.query.gender ? route.query.gender : "",
            country: route.query.country ? route.query.country : "",
            born_at: route.query.born_at ? route.query.born_at : "",
            died_at: route.query.died_at ? route.query.died_at : "",
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'stat/people/:personId',
        name: 'console_person_analysis_list_page',
        component: ConsolePersonAnalysisDetailPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'roles',
        name: 'console_role_list_page',
        component: ConsoleRoleListPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'privileges',
        name: 'console_privilege_list_page',
        component: ConsolePrivilegeListPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'reports/:model/:reportId',
        name: 'console_report_detail_page',
        component: ConsoleReportDetailPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'tags',
        name: 'console_tag_list_page',
        component: ConsoleTagListPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'reports/:model',
        name: 'console_report_list_page',
        component: ConsoleReportListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
            status: route.query.status ? route.query.status : ""
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'users',
        name: 'console_user_list_page',
        component: ConsoleUserListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
            query: route.query.query ? route.query.query : ""
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'users/:userId',
        name: 'console_user_detail_page',
        component: ConsoleUserDetailPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'settings',
        name: 'console_setting_page',
        component: ConsoleSettingPage,
        meta: {
            admin_only: true
        }
    }, {
        path: 'people',
        name: 'console_person_list_page',
        component: ConsolePersonListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : "",
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'works',
        name: 'console_work_list_page',
        component: ConsoleWorkListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            genre: ['true', 'false', true, false].includes(route.query.genre) ? route.query.genre : "",
            juvenile: ['true', 'false', true, false].includes(route.query.juvenile) ? route.query.juvenile : "",
            content_length: route.query.content_length ? route.query.content_length : "",
            type: route.query.type ? route.query.type.toString() : "",
            platform: route.query.platform ? route.query.platform.toString() : "",
            exported: route.query.exported === 'true' || route.query.exported === true,
            original_language: route.query.original_language ? route.query.original_language.split(',') : "",
            first_published_at: route.query.first_published_at ? route.query.first_published_at : "",
            first_imported_at: route.query.first_imported_at ? route.query.first_imported_at : "",
            first_simplified_imported_at: route.query.first_simplified_imported_at ? route.query.first_simplified_imported_at : "",
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : ""
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'works/recent',
        name: 'console_recent_work_list_page',
        component: ConsoleRecentWorkListPage,
        props: (route) => {
            return {
                genre: ['true', 'false', true, false].includes(route.query.genre) ? route.query.genre : "",
                juvenile: ['true', 'false', true, false].includes(route.query.juvenile) ? route.query.juvenile : "",
                content_length: route.query.content_length ? route.query.content_length : "",
                type: route.query.type ? route.query.type.toString() : "",
                original_language: route.query.original_language ? (route.query.original_language instanceof Array ? route.query.original_language : [route.query.original_language]) : "",
                published_at: route.query.published_at ? route.query.published_at : "",
                page: route.query.page ? parseInt(route.query.page) : 1,
            }
        },
        meta: {
            admin_only: true
        }
    }, {
        path: 'series',
        name: 'console_series_list_page',
        component: ConsoleSeriesListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'books',
        name: 'console_book_list_page',
        component: ConsoleBookListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1,
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : "",
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'book_series',
        name: 'console_book_series_list_page',
        component: ConsoleBookSeriesListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'platforms',
        name: 'console_platform_list_page',
        component: ConsolePlatformListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'organizations',
        name: 'console_org_list_page',
        component: ConsoleOrgListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page) : 1
        }),
        meta: {
            admin_only: true
        }
    }, {
        path: 'fulltext',
        name: 'console_fulltext_list_page',
        component: ConsoleFullTextList,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query ? route.query.query : "",
                genre: ['true', 'false', true, false].includes(route.query.genre) ? route.query.genre : "",
                juvenile: ['true', 'false', true, false].includes(route.query.juvenile) ? route.query.juvenile : "",
                content_length: route.query.content_length ? route.query.content_length : "",
                type: route.query.type ? route.query.type.toString() : "",
                platform: route.query.platform ? route.query.platform.toString() : "",
                exported: route.query.exported === 'true' || route.query.exported === true,
                original_language: route.query.original_language ? route.query.original_language.split(',') : "",
                first_published_at: route.query.first_published_at ? route.query.first_published_at : "",
                first_imported_at: route.query.first_imported_at ? route.query.first_imported_at : "",
                first_simplified_imported_at: route.query.first_simplified_imported_at ? route.query.first_simplified_imported_at : "",
                order_key: route.query.order_key ? route.query.order_key : "",
                order: route.query.order ? route.query.order : ""
            }
        },
        meta: {
            admin_only: true,
            super_only: true
        }
    }, {
        path: 'fulltext/search',
        name: 'console_fulltext_search_page',
        component: ConsoleFullTextSearchPage,
        meta: {
            admin_only: true,
            super_only: true
        }
    }, {
        path: 'fulltext/:workId',
        name: 'console_fulltext_detail_page',
        component: ConsoleFullTextDetail,
        meta: {
            admin_only: true,
            super_only: true
        }
    }]
}

export default router
