import axios from 'axios'
import config from '../../../config'
import {Message} from "element-ui";
import { g } from '../../../utils'

const baseUrl = config.server;

// create an axios instance
const service = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['exsf-tk'] = g()
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        const res = response.data
        return res;

        if (response.request.responseType === 'arraybuffer') {
            return response;
        }
        if (res.code !== "200" && res.status !== '0') {
            // alert('')
            Message({
              message: res.message || 'Error',
              type: 'error',
              showClose: true
            })
            //Promise.reject(error)
            // return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        // alert(error.response.data.message)
        console.log('err' + error) // for debug
        Message({
            message: error.response.data.message || 'Error',
            type: 'error',
            showClose: true
        })
        return Promise.reject(error)
    }
)

export default service