
export default {
    basic: {
        slogan: 'We see. We learn. We tell.',
        about: 'About CSFDB',
        volunteer: 'Volunteer Members',
        privacy: 'Privacy',
        name: 'Chinese SF Database',
        weibo: 'Weibo'
    },
    dictionaries: {
        basic: {
            all: 'All',
            person: 'People',
            work: 'Corpus',
            series: 'Series',
            export: 'Export',
            book: 'Book',
            omnibus: 'Omnibus',
            magazine: 'Magazine',
            movie: "Movie",
            episode: "Episode",
            issue: 'Issue',
            platform: 'Platform',
            organization: 'Organization',
            award: 'Award',
            tag: 'Theme',
            keyword: 'Keyword'
        },
        workType: {
            story: 'Story',
            novel: 'Novel',
            nonFiction: 'Other',
            小说: 'Fiction',
            个人小说集: 'Collection',
            多人小说集: 'Anthology',
            非虚构文集: 'Symposium',
            非虚构: 'Non-fiction/Essay',
            '漫画/连环画': 'Comic/Graphic',
            访谈: 'Interview',
            剧本: 'Play',
            诗歌: 'Poem',
            书信: 'Letter',
            编目: 'Biblio',
            其他: 'Other',
            画集: 'Paintings',
            新闻资讯: 'News',
            漫画集: 'Comic Collection'
        },
        workLength: {
            长篇: 'Novel',
            中短篇: 'Story'
        },
        gender: {
            男: 'Male',
            女: 'Female',
            其他: 'Other'
        },
        region: {
            foreign: 'Foreign',
            chinese: 'Chinese'
        },
        is_series: {
            yes: 'Series',
            no: 'Movie'
        },
        imported: {
            yes: 'Translated',
            no: 'Original'
        },
        genre: {
            yes: 'Genre',
            no: 'Non Genre'
        },
        reprint: {
            yes: 'Original',
            no: 'Reprint'
        },
        juvenile: {
            yes: 'Juvenile',
            no: 'Adult'
        },
        mainland: {
            yes: 'Mainland',
            no: 'HK&TW&Macao'
        },
        bookType: {
            小说单行本: 'Fiction',
            作品选集: 'Anthology',
            个人作品集: 'Collection',
            杂志书: 'Mook',
            教材教辅: 'Textbook',
            诗集: 'Poem',
            音像: 'Audio-Visual',
            '论文/杂文': 'Non Fiction',
            '周边/设定': 'Settings',
            画集: 'Album',
            图文书: 'Visual Book',
            传记: 'Biography',
            剧本: 'Play',
            '合订本/套装书': 'Omnibus',
            概念书: 'New Concept',
            编目: 'Bibliography',
            缩编集: 'Synopsis'
        },
        magazineType: {
            printed: 'Printed',
            digital: 'Digital'
        },
        magazinePeriod: {
            日报: 'Daily',
            周刊: 'Weekly',
            旬刊: 'Quarterly',
            半月刊: 'Fortnightly',
            月刊: 'Monthly',
            双月刊: 'Bimonthly',
            季刊: 'Seasonal',
            半年刊: 'Semiannual',
            年刊: 'Annual',
            不定刊: 'Uncertain'
        },
        orgType: {
            出版社: 'Publisher',
            文化组织: 'Book company',
            爱好者社团: 'Fan group',
            研究机构: 'Research',
            杂志社: 'Magazine',
            政府机关: 'Government',
            社会组织: 'NGO',
            影视公司: 'Movie',
            漫画公司: 'Comic',
            游戏公司: 'Game',
            其他: 'Others',
        },
        language: {
            chinese: 'Chinese',
            english: 'English'
        }
    },
    actions: {
        search: 'Search',
        login: 'Sign In',
        register: 'Sign Up',
        fullText: 'Full Text'
    },
    models: {
        country: {
            is_chinese: "China"
        },
        language: {
            is_chinese: "China"
        },
        person: {
            name: 'Name',
            realName: 'Real Name',
            foreignName: 'Foreign Name',
            gender: 'Gender',
            country: 'Region',
            bornAt: 'Born At',
            birthPlace: 'Birth Place',
            diedAt: 'Died At',
            pseudonym: 'Pseu.',
            deathPlace: 'Death Place',
            resume: 'Resume',
            themes: 'Themes',
            languageName: `{language} Name`,
        },
        work: {
            title: 'Title',
            author: 'Author',
            milestone: 'Milestone',
            publishedAt: 'Published At',
            type: 'Type',
            coAuthor: 'Co-worker',
            series: 'Series',
            textLength: 'Word Count',
            alias: 'Alias',
            length: 'Length',
            genre: 'Genre SF',
            juvenile: 'Juvenile',
            foreignOnly: 'Exported',
            platform: 'Platform Type',
            foreignTitle: 'Non-Chinese Title',
            originalLanguage: 'Original Language',
            language: 'Language',
            firstPublishedAt: 'First Published',
            firstImportedAt: 'First Imported',
            firstSimplifiedImportedAt: 'First Imported (Mainland)',
            firstExportedAt: 'First Exported',
            firstMaterializedAt: 'First Published in Print',
            firstMaterializedImportedAt: 'First Imported in Print',
            synopsis: 'Synopsis',
            introduction: 'Extra Info',
            themes: 'Themes',
            relationExplanation: 'Relation',
            publication: 'Publication',
            issue: "Issues",
            more: "Other Info"
        },
        series: {
            name: 'Name',
            originalName: 'Foreign Name',
            series: 'Belongs to',
            themes: 'Themes',
            works: 'Works',
            direct: 'Directly Under This Series',
            rank: 'Rank'
        },
        movie: {
            title: "Name",
            cast: "Cast",
            alias: "Alias",
            themes: 'Themes',
            original_title: "Foreign Name",
            timespan: "Timespan",
            timespan_e: "Episode Timespan",
            episode: "Episode",
            country: "Country/Region",
            firstReleasedAt: "First Released At",
            type: "Type",
            imported: "Imported",
            series: "TV Series",
            douban: "Douban",
            imdb: "IMDB",
            introduction: "Introduction",
            audience: "Audience",
            episodes: "Episodes"
        },
        book: {
            title: 'Title',
            type: 'Type',
            binding: 'Binding',
            publication: 'Publication',
            douban: 'Douban ID',
            page: 'Page',
            omnibus: 'Omnibus',
            description: 'Description',
            themes: 'Themes',
            contributors: 'Contributors',
            works: 'Works',
            issue: 'Related Issue',
            imported: 'Is Imported',
            reprint: 'Is Reprint',
            genre: 'Is Genre',
            mainland: 'Is Mainland',
            reader: 'Reader',
            region: 'Region',
            publishedAt: 'Published At',
            publisher: "Publisher",
            role: 'Role',
            braille: 'Braille'
        },
        omnibus: {
            publisher: 'Publisher',
            bookCount: 'Book Count',
            books: 'Books',
            contributors: 'Contributors',
        },
        bookseries: {
            publisher: 'Publisher',
            bookCount: 'Book Count',
            books: 'Books',
            contributors: 'Contributors',
        },
        magazine: {
            period: 'Period',
            type: 'Type',
            mainland: 'Is Mainland',
            professional: 'Professional',
            genre: 'Genre',
            publisher: 'Publisher',
            precursor: 'Precursor',
            introduction: 'Introduction',
            issues: 'Issues',
            works: 'Works',
            region: 'Region',
            startAt: "Start Date",
            closeAt: "Clos Date"
        },
        issue: {
            overallNumber: 'Overall Number',
            issueNumber: 'Issue Number',
            publishedAt: 'Published At',
            introduction: 'Introduction',
            themes: 'Themes',
            works: 'Works'
        },
        platform: {
            name : 'Name',
            startAt: 'Start At',
            host: 'Host',
            introduction: 'Introduction',
            mainland: 'Is Mainland',
            works: 'Works'
        },
        onlineplatform: {
            name : 'Name',
            startAt: 'Start At',
            host: 'Host',
            introduction: 'Introduction',
            mainland: 'Is Mainland',
            works: 'Works'
        },
        organization: {
            type: 'Type',
            foreign: 'Is Foreign',
            region: 'Region',
            foundedAt: 'Founded At',
            address: 'Address',
            site: 'Site',
            introduction: 'Introduction',
            members: 'Members',
            relations: 'Cooperation',
            works: 'Works',
            books: 'Books',
            omnibus: 'Omnibus',
            magazines: 'Magazines',
            awards: 'Awards',
            platforms: 'Platforms',
            hostedAwards: 'Hosted Awards',
            cooperators: 'Cooperators'
        },
        award: {
            active: 'Active',
            period: 'Period',
            host: 'Host',
            startAt: 'Start Year',
            closeAt: 'Close Year',
            introduction: 'Introduction',
            records: 'Records',
            region: 'Region',
            isActive: 'Active'
        },
        tag: {
            foreignName: 'Foreign Name',
            relation: 'Related Theme',
            introduction: 'Introduction',
            works: 'Work',
            movies: 'Movie/Series',
            glossary: 'Glossary'
        },
        theme: {
            foreignName: 'Foreign Name',
            relation: 'Related Theme',
            introduction: 'Introduction',
            works: 'Works',
            glossary: 'Glossary'
        },
        keyword: {
            related: 'Mentioned at',
            foreignName: 'Foreign Name'
        },
        publication: {
            info: 'Publication',
            type: 'Publication Type',
            alias: 'Published Title',
            contributors: 'Contributors',
            date: 'Date',
        },
        export: {
            target: 'Media',
            publishedAt: 'Published At',
            language: 'Language',
            contributors: 'Contributors'
        },
    },
    sorters: {
        person: {
            alphabet: 'Alphabet',
            birth: 'Birth'
        },
        work: {
            publication: 'Publication Date',
            import: 'Imported Date',
            import_simplified: 'Mainland Imported Date',
            influence: 'Influence',
            magazine_publication: 'Publication Date (Current Magazine)',
        },
        movie: {
            release: 'Release Date'
        },
        book: {
            publication: 'Publication Date'
        },
        issue: {
            publication: 'Publication Date'
        },
        platform: {
            publication: 'Publication Date'
        }
    },
    labels: {
        basic: {
            all: 'All',
            default: 'Default',
            order: 'Order',
            yes: 'Yes',
            no: 'No',
            now: 'Now',
            nothing: 'None',
            none: 'None',
            unknown: 'Unknown',
            empty: '404 Not Found :b',
            links: 'Links',
            contributors: 'Contributors',
            lastEditAt: 'Last edit at',
            lastEditByAdminAt: 'Last edit by admin at',
            lastEditByAdmin: 'Admin',
            relations: 'Related Entries',
            actions: 'Actions',
            noText: 'Text Unavailable',
            awards: 'Awards',
            more: "More",
            relatedPublications: 'Related Publication',
            similarWork: 'Contains some works here',
            similarAuthor: 'Contains some works by the same author',
            similarTheme: 'Similar themes'
        },
        search: {
            emptyQuery: 'Please specify your query.',
            nothing: 'No detailed introduction yet.'
        },
        home: {
            newBook: 'New Books',
            newMovie: 'New Movies / Series',
            recentWork: 'Recent Works',
            stat: 'Statistics',
            birthday: 'Birthday',
            memorial: 'Memorial',
            awardCalendar: 'Award Calendar'
        },
        person: {
            none: 'None',
            etc: 'etc.',
            unknown: 'Unknown',
            list: 'People',
            detail: 'Person Detail',
            juvenile: 'Juvenile: {count}/{total}',
            died: 'Passed Away',
            workStat: 'Work Stat',
            timeline: 'Timeline',
            works: 'Bibliography',
            awards: 'Awards',
            movies: "Movies",
            derivatives: 'Related Works (Interview, etc.)',
            other: 'Other Works (Translation, etc.)',
            publications: 'Publications',
            occupations: 'Occupations',
            editBook: 'Edited Books',
            nonGenre: 'Non Genre',
            awardedFor: 'Awarded for',
            foreign: "Foreign Publication"
        },
        work: {
            list: 'Work List',
            detail: 'Work Detail',
            translated: 'Translated',
            juvenile: 'Juvenile',
            nonGenre: 'This work is not a speculative one.',
            publications: 'Publication Records',
            book: 'Book',
            magazine: 'Magazine',
            platform: 'Platform',
            derivatives: 'Related Works (Review, etc.)',
            export: 'Translation',
            scanCode: 'Scan QR code to read full text.',
            awards: 'Awards',
            movies: "Related Movies",
            topics: 'Related Topics'
        },
        series: {
            list: 'Series List',
            detail: 'Series Detail',
            derivatives: 'Related Works (Review, etc.)',
        },
        movie: {
            single: "Movie",
            list: 'Movie/Series List',
            detail: 'Movie/Series Detail',
            awards: "Awards",
            relations: "Related Works",
            releases: "Releases",
            streams: "Stream Platform",
            secretRelease: "Not Public",
            availableOnline: "Available Online",
            watch: "Watch Online",
            certificate: "Certificate",
            casts: "Casts",
            scanCode: "Scan to watch online",
            juvenile: 'Juvenile',
            series: 'Series',
            as: "As"
        },
        episode: {
            episodeNumber: "Episode {number}"
        },
        book: {
            list: 'Book List',
            detail: 'Book Detail',
            gat: 'HK/TW/Macao',
            juvenile: 'Juvenile',
            nonGenre: 'Non-Genre',
            derivatives: 'Related Works (Review, etc.)',
            editions: 'Other Editions',
            page: "Page: {page}",
            firstPublication: 'Initial Publication',
            braille: 'Braille'
        },
        omnibus: {
            list: 'Omnibus List',
            detail: 'Omnibus Detail'
        },
        magazine: {
            list: 'Magazine List',
            detail: 'Magazine Detail',
            year: 'Year',
            gat: 'HK/TW/Macao',
        },
        issue: {
            detail: 'Issue Detail',
            prev: 'Prev',
            next: 'Next',
            derivatives: 'Related Works (Review, etc.)',
            firstPublication: 'Initial Publication'
        },
        platform: {
            list: 'Platform List',
            detail: 'Platform Detail',
            foundedAt: 'Founded in {year}',
            noFoundedAt: 'Founded Year Unknown',
            derivatives: 'Related Works (Review, etc.)',
            movies: "Streamed Movies",
            year: 'Year',
            gat: 'HK/TW/Macao',
        },
        organization: {
            list: 'Organization List',
            detail: 'Organization Detail',
            stat: 'Statistics',
            history: 'Publication by Year'
        },
        award: {
            list: 'Award List',
            detail: 'Award Detail',
            activePeriodNow: '{year} to Now',
            activePeriodAll: '{start} to {end}',
            unknown: 'Unknown',
            win: 'Win',
            fall: 'Nominated',
            categoryView: 'Category',
            sessionView: 'Session',
            byCategory: 'Categories',
            bySession: 'Sessions'
        },
        theme: {
            more: 'Please reference corresponding entry.'
        },
        topic: {
            list: 'Topic List',
            detail: 'Topic Detail',
        },
        keyword: {
            alphabet: 'Alphabet'
        }
    },
    forms: {
        search: {
            general: {
                placeholder: 'Please input your query.'
            },
            person: {
                placeholder: 'Input name or pseudonym.'
            },
            work: {
                placeholder: 'Input work title.'
            },
            series: {
                placeholder: 'Input series name.'
            },
            book: {
                placeholder: 'Input title or isbn.'
            },
            movie: {
                placeholder: 'Input title or douban/IMDB id.'
            },
            omnibus: {
                placeholder: 'Input omnibus name.'
            },
            magazine: {
                placeholder: 'Input magazine name.'
            },
            platform: {
                placeholder: 'Input platform name.'
            },
            organization: {
                placeholder: 'Input organization name.'
            },
            award: {
                placeholder: 'Input award name.'
            }
        }
    },
    messages: {
        movie: {
            firstReleasedAt: "List the following dates at most：\n1. First release date\n2. First public release date\n3. First release date in China\n4. First public release date in China\n5. First release date in Mainland China\n6. First public release date in Mainland China"
        }
    }
}
