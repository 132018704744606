
export default {
    name: "中文科幻数据库",
    server: "https://www.csfdb.cn:3001",
    //server: "https://csfdb.scifi-wiki.com:3001",
    map: {
        key: '3a3d7aabad3c5b34901cb96cb53ac8bf'
    },
    wechat: {
        appId: 'wx296a303cfe9b2dd2'
    },
    accessToken: 'fc1179ab-84ac-4168-87c7-b27220fd2813'
}
