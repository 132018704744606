import request from '../request'

export function getMessageList(params) {
    return request({
        url: `users/messages`,
        method: 'GET',
        params
    })
}

export function getMessageDetail(messageId) {
    return request({
        url: `users/messages/${messageId}`,
        method: 'GET'
    })
}