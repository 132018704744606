let MainPageLayout = () => import(/* webpackChunkName: "Guest" */"../components/layout/MainPageLayout.vue")
let HomePage = () => import(/* webpackChunkName: "Landing" */"../components/HomePage")
let SignInPage = () => import(/* webpackChunkName: "Guest" */"../components/SignInPage.vue")
let SignUpPage = () => import(/* webpackChunkName: "Guest" */"../components/SignUpPage.vue")
let SearchPage = () => import(/* webpackChunkName: "Guest" */"../components/SearchPage.vue")
let ForgetPage = () => import(/* webpackChunkName: "Guest" */"../components/ForgetPage.vue")
let ResetPasswordPage = () => import(/* webpackChunkName: "Guest" */"../components/ResetPasswordPage.vue")
let VerifyEmailPage = () => import(/* webpackChunkName: "Guest" */"../components/VerifyEmailPage.vue")
let ActivateAccountPage = () => import(/* webpackChunkName: "Guest" */"../components/ActivateAccountPage.vue")
let WorkDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/WorkDetailPage.vue")
let PersonDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/PersonDetailPage.vue")
let PersonListPage = () => import(/* webpackChunkName: "Guest" */"../components/PersonListPage.vue")
let WorkListPage = () => import(/* webpackChunkName: "Guest" */"../components/WorkListPage.vue")
let ExportedWorkListPage = () => import(/* webpackChunkName: "Guest" */"../components/ExportedWorkListPage.vue")
let BookListPage = () => import(/* webpackChunkName: "Guest" */"../components/BookListPage.vue")
let MovieListPage = () => import(/* webpackChunkName: "Guest" */"../components/MovieListPage.vue")
let MovieDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/MovieDetailPage.vue")
let EpisodeDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/EpisodeDetailPage.vue")
let MagazineListPage = () => import(/* webpackChunkName: "Guest" */"../components/MagazineListPage.vue")
let MagazineDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/MagazineDetailPage.vue")
let BookDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/BookDetailPage.vue")
let KeywordListPage = () => import(/* webpackChunkName: "Guest" */"../components/KeywordListPage")
let IssueDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/IssueDetailPage")
let AboutPage = () => import(/* webpackChunkName: "Guest" */"../components/AboutPage")
let AnnouncementPage = () => import(/* webpackChunkName: "Guest" */"../components/AnnouncementPage")
let PrivacyPage = () => import(/* webpackChunkName: "Guest" */"../components/PrivacyPage")
let OrganizationListPage = () => import(/* webpackChunkName: "Guest" */"../components/OrganizationListPage")
let OrganizationDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/OrganizationDetailPage")
let ThemeListPage = () => import(/* webpackChunkName: "Guest" */"../components/ThemeListPage")
let ThemeDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/ThemeDetailPage")
let SeriesListPage = () => import(/* webpackChunkName: "Guest" */"../components/SeriesListPage")
let SeriesDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/SeriesDetailPage")
let BookSeriesListPage = () => import(/* webpackChunkName: "Guest" */"../components/BookSeriesListPage")
let BookSeriesDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/BookSeriesDetailPage")
let AwardListPage = () => import(/* webpackChunkName: "Guest" */"../components/AwardListPage")
let AwardDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/AwardDetailPage")
let OnlinePlatformListPage = () => import(/* webpackChunkName: "Guest" */"../components/OnlinePlatformListPage")
let OnlinePlatformDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/OnlinePlatformDetailPage")
let TopicListPage = () => import(/* webpackChunkName: "Guest" */"../components/TopicListPage")
let TopicDetailPage = () => import(/* webpackChunkName: "Guest" */"../components/TopicDetailPage")
let MemorialPage = () => import(/* webpackChunkName: "Guest" */"../components/MemorialPage")
let VolunteersPage = () => import(/* webpackChunkName: "Guest" */"../components/VolunteersPage")
// 管理中心
import ConsoleRoutes from './console'
// 个人中心
import ServiceRoutes from './service'
// 第三方
// import ThirdPartyRoutes from './third-party'

const routers = [{
    path: '/sign_in',
    name: 'sign_in_page',
    component: SignInPage
}, {
    path: '/sign_up',
    name: 'sign_up_page',
    component: SignUpPage
}/*, {
    path: '/email/verification',
    name: 'verify_email_page',
    component: VerifyEmailPage
}, {
    path: '/email/activate',
    name: 'activate_account_page',
    component: ActivateAccountPage
}*/, {
    path: '/password/forget',
    name: 'forget_password_page',
    component: ForgetPage
}, {
    path: '/password/reset',
    name: 'reset_password_page',
    component: ResetPasswordPage
}, {
    path: '/info',
    component: MainPageLayout,
    meta: {
        sign_in_required: false
    },
    children: [{
        path: 'about',
        name: 'about_page',
        component: AboutPage
    }, {
        path: 'volunteer',
        name: 'volunteer_page',
        component: VolunteersPage
    }, {
        path: 'announcement',
        name: 'announcement_page',
        component: AnnouncementPage
    }, {
        path: 'privacy',
        name: 'privacy_page',
        component: PrivacyPage
    }]
}/*, ThirdPartyRoutes*/ , {
    path: '/',
    component: MainPageLayout,
    meta: {
        sign_in_required: false
    },
    children: [ServiceRoutes, {
        path: '',
        name: 'home_page',
        component: HomePage
    }, {
        path: 'memorial',
        name: 'memorial_page',
        component: MemorialPage
    }, {
        path: 'search',
        name: 'search_page',
        component: SearchPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            model: route.query.model ? route.query.model : ""
        }),
    }, {
        path: 'keywords',
        name: 'keyword_list_page',
        component: KeywordListPage,
        meta: {
            // admin_only: true
        },
        props: (route) => ({
            initial: route.query.initial ? route.query.initial : "",
            klang: route.query.klang ? route.query.klang : ""
        }),
    }, {
        path: 'topics',
        name: 'topic_list_page',
        component: TopicListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1
        }),
    }, {
        path: 'topics/:topicId',
        name: 'topic_detail_page',
        component: TopicDetailPage
    }, {
        path: 'works/:workId',
        name: 'work_detail_page',
        component: WorkDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'people/:personId',
        name: 'person_detail_page',
        component: PersonDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'people',
        name: 'person_list_page',
        component: PersonListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            gender: route.query.gender ? route.query.gender : "",
            country: route.query.country ? route.query.country : "",
            born_at: route.query.born_at ? route.query.born_at : "",
            died_at: route.query.died_at ? route.query.died_at : "",
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'awards',
        name: 'award_list_page',
        component: AwardListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            region: route.query.region || "",
            active: route.query.active === 'true' || route.query.active === true,
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'awards/:awardId',
        name: 'award_detail_page',
        component: AwardDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'works',
        name: 'work_list_page',
        component: WorkListPage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query ? route.query.query : "",
                genre: ['true', 'false', true, false].includes(route.query.genre) ? route.query.genre : "",
                juvenile: ['true', 'false', true, false].includes(route.query.juvenile) ? route.query.juvenile : "",
                content_length: route.query.content_length ? route.query.content_length : "",
                type: route.query.type ? route.query.type.toString() : "",
                platform: route.query.platform ? route.query.platform.toString() : "",
                exported: route.query.exported === 'true' || route.query.exported === true,
                original_language: route.query.original_language ? route.query.original_language.split(',') : "",
                first_published_at: route.query.first_published_at ? route.query.first_published_at : "",
                first_imported_at: route.query.first_imported_at ? route.query.first_imported_at : "",
                first_simplified_imported_at: route.query.first_simplified_imported_at ? route.query.first_simplified_imported_at : "",
                order_key: route.query.order_key ? route.query.order_key : "",
                order: route.query.order ? route.query.order : ""
            }
        },
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'export',
        name: 'exported_work_list_page',
        component: ExportedWorkListPage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query ? route.query.query : "",
                genre: ['true', 'false', true, false].includes(route.query.genre) ? route.query.genre : "",
                juvenile: ['true', 'false', true, false].includes(route.query.juvenile) ? route.query.juvenile : "",
                content_length: route.query.content_length ? route.query.content_length : "",
                type: route.query.type ? route.query.type.toString() : "",
                exported: route.query.exported === 'true' || route.query.exported === true,
                language: route.query.language ? route.query.language.split(',') : "",
                published_at: route.query.published_at ? route.query.published_at : "",
                order_key: route.query.order_key ? route.query.order_key : "",
                order: route.query.order ? route.query.order : ""
            }
        },
    }, {
        path: 'books',
        name: 'book_list_page',
        component: BookListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            type: !isNaN(parseInt(route.query.type)) ? route.query.type.toString() : "",
            binding: !isNaN(parseInt(route.query.binding)) ? route.query.binding.toString() : "",
            is_imported: route.query.is_imported || route.query.is_imported === false ? route.query.is_imported.toString() : '',
            genre: route.query.genre || route.query.genre === false ? route.query.genre.toString() : '',
            reprint: route.query.reprint || route.query.reprint === false ? route.query.reprint.toString() : '',
            is_mainland: route.query.is_mainland || route.query.is_mainland === false ? route.query.is_mainland.toString() : '',
            is_foreign: route.query.is_foreign || route.query.is_foreign === false ? route.query.is_foreign.toString() : '',
            is_braille: route.query.is_braille || route.query.is_braille === false ? route.query.is_braille.toString() : '',
            country: route.query.country ? route.query.country : "",
            juvenile: route.query.juvenile || route.query.juvenile === false ? route.query.juvenile.toString() : '',
            published_at: route.query.published_at ? route.query.published_at : "",
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : ""
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'books/:bookId',
        name: 'book_detail_page',
        component: BookDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'movies',
        name: 'movie_list_page',
        component: MovieListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            type: !isNaN(parseInt(route.query.type)) ? route.query.type.toString() : "",
            is_imported: route.query.is_imported || route.query.is_imported === false ? route.query.is_imported.toString() : '',
            is_series: route.query.is_series || route.query.is_series === false ? route.query.is_series.toString() : '',
            juvenile: route.query.juvenile || route.query.juvenile === false ? route.query.juvenile.toString() : '',
            country: route.query.country ? route.query.country : "",
            first_released_at: route.query.first_released_at ? route.query.first_released_at : "",
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : ""
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'movies/:movieId',
        name: 'movie_detail_page',
        component: MovieDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'movies/:movieId/episodes/:episodeId',
        name: 'episode_detail_page',
        component: EpisodeDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'series',
        name: 'series_list_page',
        component: SeriesListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : ""
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'omnibus',
        name: 'book_series_list_page',
        component: BookSeriesListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : ""
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'series/:seriesId',
        name: 'series_detail_page',
        component: SeriesDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'omnibus/:seriesId',
        name: 'book_series_detail_page',
        component: BookSeriesDetailPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'magazines',
        name: 'magazine_list_page',
        component: MagazineListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            period: route.query.period ? route.query.period : "",
            type: route.query.type ? route.query.type : "",
            professional: ['true', 'false', true, false].includes(route.query.professional) ? route.query.professional : "",
            is_core: ['true', 'false', true, false].includes(route.query.is_core) ? route.query.is_core : "",
            is_mainland: route.query.is_mainland || route.query.is_mainland === false ? route.query.is_mainland.toString() : '',
            is_foreign: route.query.is_foreign || route.query.is_foreign === false ? route.query.is_foreign.toString() : '',
            country: route.query.country ? route.query.country : "",
            start_at: route.query.start_at ? route.query.start_at : "",
            close_at: route.query.close_at ? route.query.close_at : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'magazines/:magazineId',
        name: 'magazine_detail_page',
        component: MagazineDetailPage,
        props: (route) => ({
            order: route.query.order ? route.query.order : "",
            year: route.query.year ? route.query.year : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'platforms',
        name: 'online_platform_list_page',
        component: OnlinePlatformListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            is_mainland: route.query.is_mainland || route.query.is_mainland === false ? route.query.is_mainland.toString() : '',
            is_foreign: route.query.is_foreign || route.query.is_foreign === false ? route.query.is_foreign.toString() : '',
            country: route.query.country ? route.query.country : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'platforms/:platformId',
        name: 'online_platform_detail_page',
        component: OnlinePlatformDetailPage,
        props: (route) => ({
            order: route.query.order ? route.query.order : "",
            year: route.query.year ? route.query.year : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'organizations',
        name: 'organization_list_page',
        component: OrganizationListPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            query: route.query.query ? route.query.query : "",
            type: route.query.type ? route.query.type.toString() : "",
            found_at: route.query.found_at ? route.query.found_at : "",
            is_foreign: route.query.is_foreign || route.query.is_foreign === false ? route.query.is_foreign.toString() : '',
            country: route.query.country ? route.query.country : "",
        }),
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'organizations/:organizationId',
        name: 'organization_detail_page',
        component: OrganizationDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'magazines/:magazineId/issues/:issueId',
        name: 'magazine_issue_detail_page',
        component: IssueDetailPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'themes',
        name: 'theme_list_page',
        component: ThemeListPage,
        meta: {
            sign_in_required: false
        }
    }, {
        path: 'themes/:themeId',
        name: 'theme_detail_page',
        component: ThemeDetailPage,
        props: (route) => ({
            page: route.query.page ? parseInt(route.query.page): 1,
            order_key: route.query.order_key ? route.query.order_key : "",
            order: route.query.order ? route.query.order : ""
        }),
        meta: {
            sign_in_required: false
        }
    }]
}, ConsoleRoutes, {
    path: '*',
    redirect: '/'
}];

export default routers
